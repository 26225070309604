import { ILangWidget } from 'src/types/product';

export const LangSubheading: ILangWidget = [
  {
    feId: 'Subheading',
    desc: [
      { lang: 'en', val: 'Subheading' },
      { lang: 'de', val: 'Subheading' }, // Assuming no translation required for 'Youtube Embed ID'
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'SubheadingOrientation',
    desc: [
      { lang: 'en', val: 'Orientation' },
      { lang: 'de', val: 'Ausrichtung' }, 
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
];