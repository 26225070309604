import { ILangWidget } from 'src/types/product';

export const LangHeading: ILangWidget = [
  {
    feId: 'HeadingText',
    desc: [
      { lang: 'en', val: 'Heading' },
      { lang: 'de', val: 'Lorem Ipsum' }, // Assuming 'Lorem Ipsum' remains untranslated or adjust as needed
    ],
    value: [
      { lang: 'en', val: ['Lorem Ipsum'] },
      { lang: 'de', val: ['Lorem Ipsum'] }, // Assuming same value for German, adjust if necessary
    ],
  },
  {
    feId: 'HeadingOrientation',
    desc: [
      { lang: 'en', val: 'Orientation' },
      { lang: 'de', val: 'Ausrichtung' }, // Assuming translation for 'Orientation' to 'Ausrichtung'
    ],
    value: [
      { lang: 'en', val: ['center'] },
      { lang: 'de', val: ['center'] }, // Assuming same value for both languages
    ],
  },
];