
// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
  MANAGEMENT: '/management',
  LABEL: '/label',
  GS1: '/01'
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  // AUTH
  auth: {
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/JlX5DUeSU3wTvyOxFQFC8/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    permission: `${ROOTS.DASHBOARD}/permission`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      new: `${ROOTS.DASHBOARD}/product/new`,
      list: `${ROOTS.DASHBOARD}/product/list`,
      pass: (productId: string) => `${ROOTS.DASHBOARD}/product/pass/${productId}`,
      template: (productId: string) => `${ROOTS.DASHBOARD}/product/template/${productId}`,
      version: (productId: string) => `${ROOTS.DASHBOARD}/product/version/${productId}`,
    },
    data: {
      root: `${ROOTS.DASHBOARD}/data`,
      widget: (widgetId: string) => `${ROOTS.DASHBOARD}/data/widget/${widgetId}`,
      child: (childId: string) => `${ROOTS.DASHBOARD}/data/child/${childId}`,
      product: (productId: string) => `${ROOTS.DASHBOARD}/data/product/${productId}`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user/account`,
      account: `${ROOTS.DASHBOARD}/user/account`,
    },
    management: {
      root: `${ROOTS.DASHBOARD}/management/account`,
      account: `${ROOTS.DASHBOARD}/management/account`,
      userslist: `${ROOTS.DASHBOARD}/management/userslist`,
    },
    checkout: {
      root: `${ROOTS.DASHBOARD}/checkout`,
      success: (session_id: string) => `${ROOTS.DASHBOARD}/checkout/success/${session_id}`,
    },
    analytics: {
      root: `${ROOTS.DASHBOARD}/analytics`,
    },
  },
  label: {
    root: ROOTS.LABEL,
    labelId: (labelId: string) => `${ROOTS.LABEL}/${labelId}`,
  },
  gs1: {
    root: ROOTS.GS1,
    gtin: (gtin: string) => `${ROOTS.GS1}/${gtin}`,
    x: (gtin: string) => `${ROOTS.GS1}/${gtin}`,
  },
};
