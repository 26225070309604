import { ILangWidget } from 'src/types/product';

export const LangProductInfo: ILangWidget = [
  {
    feId: 'WidgetHeadline_ProductInformation',
    desc: [
      { lang: 'en', val: 'Widget Headline' },
      { lang: 'de', val: 'Product Information' },
    ],
    value: [
      { lang: 'en', val: ['Product Information'] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'ShowWidgetHeadline',
    desc: [
      { lang: 'en', val: 'Show Widget Headline' },
      { lang: 'de', val: 'Show Widget Headline' },
    ],
    value: [
      { lang: 'en', val: ['X'] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'Description',
    desc: [
      { lang: 'en', val: 'Description' },
      { lang: 'de', val: 'Description' },
    ],
    value: [
      { lang: 'en', val: ['Lorem ipsum'] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'BasicInformation',
    desc: [
      { lang: 'en', val: 'Basic Information' },
      { lang: 'de', val: 'Basic Information' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'WidgetHeadline_Nutrients',
    desc: [
      { lang: 'en', val: 'Widget Headline' },
      { lang: 'de', val: 'Nutrients' },
    ],
    value: [
      { lang: 'en', val: ['Nutrients'] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'Nutrients',
    desc: [
      { lang: 'en', val: 'Nutrients' },
      { lang: 'de', val: 'Nutrients' },
    ],
    value: [
      {
        lang: 'en',
        val: [
          { key: 'Energy', value: 0 },
          {
            key: 'Fat',
            value: 0,
            subvalues: [
              { key: 'Saturated Fat', value: 0 },
              { key: 'Unsaturated Fat', value: 0 },
            ],
          },
          {
            key: 'Carbohydrates',
            value: 0,
            subvalues: [
              { key: 'Sugar', value: 0 },
              { key: 'Polyhydric Alcohols', value: 0 },
              { key: 'Starch', value: 0 },
            ],
          },
          { key: 'Dietary Fiber', value: 0 },
          { key: 'Protein', value: 0 },
          { key: 'Salt', value: 0 },
        ],
      },
      {
        lang: 'de',
        val: [
          { key: 'Energy', value: 0 },
          {
            key: 'Fat',
            value: 0,
            subvalues: [
              { key: 'Saturated Fat', value: 0 },
              { key: 'Unsaturated Fat', value: 0 },
            ],
          },
          {
            key: 'Carbohydrates',
            value: 0,
            subvalues: [
              { key: 'Sugar', value: 0 },
              { key: 'Polyhydric Alcohols', value: 0 },
              { key: 'Starch', value: 0 },
            ],
          },
          { key: 'Dietary Fiber', value: 0 },
          { key: 'Protein', value: 0 },
          { key: 'Salt', value: 0 },
        ],
      },
    ],
  },
  {
    feId: 'CustomFields',
    desc: [
      { lang: 'en', val: 'Custom Fields' },
      { lang: 'de', val: 'Custom Fields' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'ServingUnit',
    desc: [
      { lang: 'en', val: 'Serving Unit' },
      { lang: 'de', val: 'Serving Unit' },
    ],
    value: [
      { lang: 'en', val: ['g'] },
      { lang: 'de', val: ['g'] },
    ],
  },
  {
    feId: 'ReferenceQuantity',
    desc: [
      { lang: 'en', val: 'Reference Quantity' },
      { lang: 'de', val: 'Reference Quantity' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'ServingSize',
    desc: [
      { lang: 'en', val: 'Serving Size' },
      { lang: 'de', val: 'Serving Size' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'EnableServingSize',
    desc: [
      { lang: 'en', val: 'Enable Serving Size' },
      { lang: 'de', val: 'Enable Serving Size' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'WidgetHeadline_Ingredients',
    desc: [
      { lang: 'en', val: 'Ingredients & Allergens' },
      { lang: 'de', val: 'Ingredients & Allergens' },
    ],
    value: [
      { lang: 'en', val: ['Ingredients & Allergens'] },
      { lang: 'de', val: ['Ingredients & Allergens'] },
    ],
  },
  {
    feId: 'Ingredients',
    desc: [
      { lang: 'en', val: 'Ingredients' },
      { lang: 'de', val: 'Ingredients' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'SelectAllergens',
    desc: [
      { lang: 'en', val: 'Select Allergens' },
      { lang: 'de', val: 'Select Allergens' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'NotesAndWarnings',
    desc: [
      { lang: 'en', val: 'Notes and Warnings' },
      { lang: 'de', val: 'Notes and Warnings' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'WidgetHeadline_Distributor',
    desc: [
      { lang: 'en', val: 'Widget Headline' },
      { lang: 'de', val: 'Distributor' },
    ],
    value: [
      { lang: 'en', val: ['Distributor'] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'ResponsibleDistributor',
    desc: [
      { lang: 'en', val: 'Responsible Distributor' },
      { lang: 'de', val: 'Responsible Distributor' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'PostalCode',
    desc: [
      { lang: 'en', val: 'Postal Code' },
      { lang: 'de', val: 'Postal Code' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'City',
    desc: [
      { lang: 'en', val: 'City' },
      { lang: 'de', val: 'City' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'Street',
    desc: [
      { lang: 'en', val: 'Street' },
      { lang: 'de', val: 'Street' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'Link',
    desc: [
      { lang: 'en', val: 'Link' },
      { lang: 'de', val: 'Link' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'Email',
    desc: [
      { lang: 'en', val: 'Email' },
      { lang: 'de', val: 'Email' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'ProducedIn',
    desc: [
      { lang: 'en', val: 'Produced in' },
      { lang: 'de', val: 'Produced in' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'WidgetHeadline_Storage',
    desc: [
      { lang: 'en', val: 'Widget Headline' },
      { lang: 'de', val: 'Storage Instructions' },
    ],
    value: [
      { lang: 'en', val: ['Storage Instructions'] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'StorageFields',
    desc: [
      { lang: 'en', val: 'Storage Fields' },
      { lang: 'de', val: 'Storage Fields' },
    ],
    value: [
      {
        lang: 'en',
        val: [
          { key: 'Temperature', value: '' },
          { key: 'Humidity', value: '' },
        ],
      },
      { lang: 'de', val: [] },
    ],
  },
];