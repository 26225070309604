//
import { v4 as uuid } from 'uuid';
import { IWidget } from 'src/types/product';
import { getDesc, getValue } from '../_langWidgets';

// ----------------------------------------------------------------------

export const ProductHead: IWidget = {
  id: uuid(),
  name: 'ProductHead',
  order: 0,
  category: 'Required',
  open: true,
  label: {
    id: 'producthead',
    name: 'Product Head',
    color: 'black',
  },
  childs: [
    {
      id: 'Head',
      open: true,
      show: true,
      attributes: {
        '1': {
          feId: 'BrandName',
          change: true,
          translate: true,
          name: getDesc('BrandName'),
          value: getValue('BrandName'),
        },
        '2': {
          feId: 'ProductName',
          change: true,
          translate: true,
          name: getDesc('ProductName'),
          value: getValue('ProductName'),
        },
        '3': {
          feId: 'ShowProductTags',
          change: false,
          translate: false,
          name: getDesc('ShowProductTags'),
          value: getValue('ShowProductTags'),
        },
        '4': {
          feId: 'ProductTags',
          change: true,
          translate: true,
          name: getDesc('ProductTags'),
          value: getValue('ProductTags'),
        },
        '5': {
          feId: 'ProductPicture',
          change: true,
          translate: false,
          name: getDesc('ProductPicture'),
          value: getValue('ProductPicture'),
        },
        '6': {
          feId: 'ProductBanner',
          change: false,
          translate: false,
          name: getDesc('ProductBanner'),
          value: getValue('ProductBanner'),
        },
        '7': {
          feId: 'ProductFilename',
          change: true,
          translate: false,
          name: getDesc('ProductFilename'),
          value: getValue('ProductFilename'),
        },
        '8': {
          feId: 'BannerFilename',
          change: false,
          translate: false,
          name: getDesc('BannerFilename'),
          value: getValue('BannerFilename'),
        },
        '9': {
          feId: 'ShowProduct',
          change: false,
          translate: false,
          name: getDesc('ShowProduct'),
          value: getValue('ShowProduct'),
        },
        '10': {
          feId: 'ShowBanner',
          change: false,
          translate: false,
          name: getDesc('ShowBanner'),
          value: getValue('ShowBanner'),
        },
      },
    },
  ],
  style: {
    objects: {
      '1': {
        name: 'Orientation',
        value: ['left'],
      },
      '2': {
        name: 'Product Shape',
        value: ['round'],
      },
      '3': {
        name: 'Product Size',
        value: ['s'],
      },
      '4': {
        name: 'Banner Shape',
        value: ['square'],
      },
      '5': {
        name: 'Banner Size',
        value: ['21/9'],
      },
      '6': {
        name: 'Name Size',
        value: ['s'],
      },
      '7': {
        name: 'Name Color',
        value: [''],
      },
      '8': {
        name: 'Card Background',
        value: [''],
      },
    },
  },
};
